@charset "UTF-8";
/**
 * Shards — Demo Page Adjustments
 */
.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 1500;
  transition: opacity 500ms ease-in-out;
}

.page-loader {
  width: 40px;
  height: 40px;
  margin: auto;
  top: 50%;
  left: 50%;
  position: absolute;
  margin-top: -20px;
  margin-left: -20px;
  background-color: #333;
  background: #007bff;
  border-radius: 100%;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-animation: pulse-load 1200ms infinite ease-in-out;
  animation: pulse-load 1200ms infinite ease-in-out;
}

@-webkit-keyframes pulse-load {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes pulse-load {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.fb-share-button > span,
.fb-like > span {
  height: 34px !important;
}

.welcome {
  background: #fff;
  height: 100vh;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.welcome h1 {
  font-size: 6.25rem;
  font-weight: 500;
  letter-spacing: -0.3125rem;
  margin-top: 35vh;
  margin-bottom: 20px;
  color: #1f2429;
}

@media (max-width: 575px) {
  .welcome h1 {
    font-size: 5.90rem;
  }
}

.welcome .inner-wrapper {
  z-index: 1;
}

.welcome .inner-wrapper > p {
  margin-bottom: 20px;
  color: #5f738e;
  transition-delay: 150ms;
}

.welcome .inner-wrapper > .action-links {
  transition-delay: 300ms;
}

.welcome .product-by {
  margin-bottom: 30px;
}

.welcome .product-by a:hover {
  text-decoration: none;
}

.welcome .product-by p {
  font-size: 10px;
  color: #b1b4bd;
  text-transform: uppercase;
  margin: 0;
}

.welcome .product-by img {
  max-width: 180px;
}

.welcome:before, .welcome:after {
  content: '';
  position: absolute;
  width: 50vw;
  height: 80vh;
  transform: rotate(-25deg);
}

.welcome:before {
  left: -15vw;
  top: -30%;
  background: #fff;
  background: -moz-linear-gradient(left, #e2e7ef 0%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #e2e7ef 0%, #ffffff 100%);
  background: linear-gradient(to right, #e2e7ef 0%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e7ef', endColorstr='#ffffff', GradientType=1);
}

@media (max-width: 991px) {
  .welcome:before {
    left: -30%;
  }
}

@media (max-width: 767px) {
  .welcome:before {
    left: -35%;
  }
}

.welcome:after {
  right: -5vw;
  top: -15%;
  background: #ffffff;
  background: -moz-linear-gradient(left, #ffffff 0%, #e2e7ef 100%);
  background: -webkit-linear-gradient(left, #ffffff 0%, #e2e7ef 100%);
  background: linear-gradient(to right, #ffffff 0%, #e2e7ef 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e2e7ef', GradientType=1);
}

@media (max-width: 991px) {
  .welcome:after {
    right: -17%;
  }
}

.shard {
  will-change: transform;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 40px;
  z-index: 1;
  width: 280px;
  animation: float 7s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translate(-50%, 0px);
  }
  50% {
    transform: translate(-50%, -20px);
  }
  100% {
    transform: translate(-50%, 0px);
  }
}

.page-content {
  position: relative;
  background: #fafafa;
  padding-top: 5.3125rem;
}

.page-content:before {
  content: '';
  height: 500px;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: -moz-linear-gradient(top, #ffffff 0%, #fafafa 100%);
  background: -webkit-linear-gradient(top, #ffffff 0%, #fafafa 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #fafafa 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#fafafa', GradientType=0);
}

.page-content .color-wrapper {
  float: left;
}

@media (max-width: 575px) {
  .page-content .color-wrapper {
    max-width: 230px;
    margin: 0 auto;
  }
}

.page-content .color {
  padding: 25px 20px;
  text-align: center;
  background: #fff;
  margin-bottom: 30px;
  box-shadow: 0 0 25px rgba(40, 47, 60, 0.05), 0 20px 25px rgba(40, 47, 60, 0.05), 0 3px 4px rgba(40, 47, 60, 0.05);
}

.page-content .color:last-child {
  margin-right: 0;
}

.page-content .color .swatch {
  position: relative;
  border-radius: 50%;
  margin: 0 auto 15px auto;
  width: 110px;
  height: 110px;
}

.page-content .color .title {
  display: inline-block;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  width: 100%;
  font-size: 1rem;
}

.page-content .color .hex-value {
  font-family: "Roboto Mono", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  color: #8f99ac;
  font-size: 12px;
  text-transform: uppercase;
}

.page-content .content .example {
  margin: 45px 0 60px 0;
}

.page-content .content .example.emphasized {
  padding: 25px 20px;
  background: #fff;
  box-shadow: 0 0 25px rgba(40, 47, 60, 0.05), 0 20px 25px rgba(40, 47, 60, 0.05), 0 3px 4px rgba(40, 47, 60, 0.05);
}

.page-content .content .table {
  background: #fff;
  box-shadow: 0 0 25px rgba(40, 47, 60, 0.05), 0 20px 25px rgba(40, 47, 60, 0.05), 0 3px 4px rgba(40, 47, 60, 0.05);
}

.page-content .content .table td,
.page-content .content .table th {
  padding: 30px 25px;
}

.page-content .content .table th {
  padding: 15px 25px;
  font-size: 11px;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1;
  color: #9ba4ae;
  border: none;
}

.page-content .content .table tbody td {
  font-size: 0.8125rem;
  vertical-align: middle;
}

.page-content .content .table tbody td h1,
.page-content .content .table tbody td h2,
.page-content .content .table tbody td h3,
.page-content .content .table tbody td h4,
.page-content .content .table tbody td h5,
.page-content .content .table tbody td h6,
.page-content .content .table tbody td p {
  margin: 0;
  line-height: 1;
}

.page-content .content .table tbody td p {
  font-size: 1rem !important;
}

@media (max-width: 767px) {
  .sm-hidden {
    display: none;
  }
}

.ll-image {
  text-indent: -9000px;
}

.hidden {
  opacity: 0;
}

.slide-in {
  will-change: transform;
  opacity: 0;
  transform: translateY(50%);
  transition: transform 850ms cubic-bezier(0.785, 0.135, 0.15, 0.86), opacity 850ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.slide-in.visible {
  transform: translateY(0);
  opacity: 1;
}

.icons-example .icons-example-wrapper {
  padding: 100px 0;
}

@media (max-width: 850px) {
  .icons-example .icons-example-wrapper {
    min-width: 100%;
  }
}

.icons-example .icons-example-wrapper.material-icons {
  background: #1C1E21;
}

.icons-example .icons-example-wrapper.font-awesome {
  background: #212529;
}

@media (max-width: 1199px) {
  .example-buttons .buttons-wrapper button {
    margin: 0 5px;
  }
}

@media (max-width: 850px) {
  .example-buttons .buttons-wrapper {
    flex-flow: column !important;
    flex-flow: wrap;
  }
  .example-buttons .buttons-wrapper button {
    min-width: 30%;
    margin-bottom: 20px;
    flex: 1;
  }
}

@media (max-width: 450px) {
  .example-buttons .buttons-wrapper button {
    min-width: 50%;
  }
}

@media (max-width: 991px) {
  #cards .last {
    display: block !important;
  }
}

@media (max-width: 767px) {
  #cards .card {
    max-width: 350px;
    display: table;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 767px) {
  #progress-bars .pb-widths {
    margin-bottom: 1.875rem;
  }
}

@media (max-width: 767px) {
  #popups-popovers .row > div {
    margin-bottom: 1.875rem;
  }
}

@media (max-width: 420px) {
  #popups-popovers button {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  #badges .badge {
    display: table;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    width: 100%;
  }
}

@media (max-width: 767px) {
  #forms .custom-dropdown-example {
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 767px) {
  .custom-controls-example {
    width: 100%;
    max-width: 100%;
    flex: none;
    display: block;
    padding: 0 !important;
    margin-bottom: 1.5rem;
  }
  .custom-controls-example:last-child {
    margin-bottom: 0;
  }
}

#documentation {
  border-top: 1px solid #ddd;
}

.footer-cta {
  padding: 100px 0;
}

.main-footer a:hover {
  text-decoration: none;
}

/*# sourceMappingURL=shards-demo.css.map */